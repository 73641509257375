<!--  -->
<template>
    <div class="homecont">
      <HeadNav></HeadNav>
  
      <div class="homeLun">
        <img class="wecardImg" src="../assets/some/15.png" alt="zhanwei"/>
      </div>
      <div class="homeYing">
        <div class="newhomeChanLft">
          <div class="newhomeChanLftBox">
            <div class="homeChanLftsall ceshiBaccolor newborr">Application</div>
            <div class="homeChanLftsall" v-for="(it,index) in datalist" :key="index"
              @click="handleChangeChild(it.id)" :class="it.status == true ? 'ceshiBaccolor':''">
              {{it.name}}
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>

            <div class="homeYingForm">
              <div class="homeWoBox-title">Online message</div>
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="84px" class="demo-ruleForm">
                  <el-form-item label="Name" prop="name">
                      <el-input v-model="ruleForm.name"></el-input>
                  </el-form-item>
                  <el-form-item label="Mobile" prop="phone">
                      <el-input v-model="ruleForm.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                      <el-input v-model="ruleForm.email"></el-input>
                  </el-form-item>
                  <el-form-item label="Country" prop="country">
                      <el-input v-model="ruleForm.country"></el-input>
                  </el-form-item>
                  <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                      <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
                  </el-form-item>
                  <el-form-item>
                      <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
                  </el-form-item>
              </el-form>
            </div>
        </div>

        <div v-if="listShow" class="newBoxChan">
          <div class="homeYingrig newBoxChanChan">
              <div class="newhomeYing"  v-for="(item,index) in tableList" :key="index" @click="seeDetailsfun(item.id)">
                  <img :src="item.img"  alt="zhanwei2"/>
                  <div>{{item.title}}</div>
              </div>
              <div class="zanweshu" v-if="tableList.length <= 0">暂无数据</div>
          </div>
          <div class="paginationNewbox">
              <el-pagination  layout="total, sizes, prev, pager, next,jumper"
              :total="total" :page-size="pageSize" :current-page="currentPage"
              @current-change="handleCurrentChange"  @size-change="handlePageSize"
              class="fr mr-20"></el-pagination>
          </div>
        </div>
        <div class="homeChanRigDetails newhomeyingFuTop" v-else>
            <div class="homeChanRigDetailsTop">
                `<div class="homeChanRigDetailsTopLf newhomeyingFu">{{contentDetails.title}}</div>
                <div class="homeChanRigDetailsTopLf newhomeyingFu">
                  <img  :src="contentDetails.img" alt="zhanwei3"/>
                </div>
                <!-- <div class="homeChanRigDetailsTopRig">
                    <div class="homeChanRigDetailsTopRig-tle">{{contentDetails.title}}</div>
                    <div class="homeChanRigDetailsTopRig-btm">{{contentDetails.caseText}}</div>
                </div> -->
            </div>
            <div class="homeChanRigDetailsBtm">
                <!-- <div class="homeChanRigDetailsBtm-top">Details</div> -->
                <div class="homeChanRigDetailsBtm-btm">
                    <p v-html="contentDetails.content"></p>
                </div>
            </div>
        </div>


      </div>
      <Bottom></Bottom>
      <Sidebar></Sidebar>
    </div>
  </template>
  
  <script>
  import HeadNav from "../components/headNav";
  import Bottom from "../components/bottom";
  import Sidebar from "../components/sidebar";
  import { APILogin } from "../api/APILogin";
  import {Dialog} from 'vant';
  export default {
    metaInfo: {
      title: 'Application cases',
      meta: [
        {
          name: 'Application cases',
          content: 'SiC powder,Induction Heating Machine,Induction Heating power supply,water Cooling system,Disk CNC quenching machine tool,quenching machine tool,High precision power supply'
        },
        {
          name: 'description',
          content: ' Shanghai Junko New Energy Technology Co., Ltd. is a high-tech enterprise located in Changning District, Shanghai, focusing on the research and development, production, and sales of induction heating technology and semi-conductor materials. We provide efficient and energy-saving induction heating equipment to help improve industrial production efficiency, focus on innovation in semiconductor materials,promote the green energy revolution, and con-tribute to global sustainable development.'
        }
      ]
    },
    name: "homeYing",
    components: {
      HeadNav,
      Bottom,
      Sidebar,
    },
    data() {
      return {
        activeNames: ['1'],
        activeNamesChild: ['01'],
        datalist: [
            // {
            //     id: '1',
            //     name: 'Application',
            //     status: true,
            // }, 
            {
                id: '2',
                name: 'Heat treatment applications',
                status: false,
            },
            {
                id: '3',
                name: 'Induction heating',
                status: false,
            },
            {
                id: '4',
                name: 'Brazing applications',
                status: false,
            },
            {
                id: '5',
                name: 'Melting application',
                status: false,
            },{
                id: '6',
                name: 'Forging applications',
                status: false,
            },
        ],
        contlist: [],

        ruleForm: {
          name: '',
          country: '',
          email: '',
          message: '',
          phone: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
          ],
          message: [
            { required: true, message: '请输入留言', trigger: 'blur' },
          ],
        },

        windowHeight: (document.documentElement.clientHeight - 100),   //实时屏幕高度

        fenleiId: '', // 分类id
        listShow: true,
        total: 0,
        currentPage: 1,
        pageSize: 10,
        tableList: [],
        contentDetails: '',

        stateValBao: '',
      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
      // windowHeight (val) {
      //   let that = this;
      //   console.log("实时屏幕高度：",val, that.windowHeight );
      // },
      
    },
    filters: {},
    //方法集合
    methods: {

        // 查看详情
        seeDetailsfun(itemVal) {
            APILogin.getAnChanDetails({
                id: itemVal, // 分类id
            }).then(res => {
                this.contentDetails = res.data
                this.listShow = false
                //console.log("res>>>>" + JSON.stringify(this.contentDetails))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
        },


        // 提交留言
        submitForm() {
            APILogin.messageAdd({
                name: this.ruleForm.name, 
                country: this.ruleForm.country,
                email: this.ruleForm.email, 
                message: this.ruleForm.message,
                phone: this.ruleForm.phone, 
            }).then(res => {
                Dialog.alert({
                    message: 'Thank you for your message. Someone will contact you later',
                })
                console.log("res>>>>" + JSON.stringify(res.data))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
        },
        handleChangeChild(val) {
            //console.log("2222>>>>>>",JSON.stringify(val));
            if(val == '1') {
              val = ''
            }
            this.fenleiId = val
            this.datalist.map(item=>{
              if(item.id == val) {
                item.status = !item.status
              }else {
                item.status = false
              }
            })

            this.listShow = true
            this.currentPage = 1
            this.pageSize = 10
            this.tableList = []
            this.getDataList()
            
        },
        handleChange(val) {
            console.log(">>>>>>",JSON.stringify(val));
        },


  
  
      choseClick(item) {
          console.log(">>>>>>>",JSON.stringify(item))
          this.$router.push({ 
              path: '/pcyaoqwenDetails', 
              query: { 
                  auditID: item.id,  
              } 
          });
      },
        // 获取产品列表数据
        getDataList() {
          APILogin.getAnliChanList({
              page: this.currentPage, // 分页参几页
              limit: this.pageSize, // 分页参每页几条
              categoryId: this.fenleiId, // 分类id
          }).then(res => {
              this.tableList = res.data.data
              this.total = res.data.data.length
              // console.log("11111>>>>" + JSON.stringify(this.tableList))
          }).catch(fail => {
              console.log("fail>>>>" + JSON.stringify(fail))
          })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getDataList()
        },
        handlePageSize(val) {
            this.currentPage = 1
            this.pageSize = val
            this.getDataList()
        },






    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
      document.title = "应用中心"
      // 获取产品列表数据
      this.getDataList()
      let state = this.$route.query.state
      console.log("纯子能源>>>>>>>>>>",state)
      this.stateValBao = this.$route.query.typeVal || ''
      //console.log("跳转进产品详情>>>>>>>>>>",JSON.stringify(this.stateValBao) )
      if(this.stateValBao != '') {
          this.listShow = false
          this.seeDetailsfun(this.stateValBao)
      }
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
  
      // <!--把window.onresize事件挂在到mounted函数上--      
    },
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
  <style lang="less" scoped src="@/styles/pc/home.less"></style>
  <style lang="less" scoped src="@/styles/pc/homewe.less"></style>
  <style lang="less">
      .ceshidiv {
      .el-form-item__label {
        line-height: 16px;
        margin-top: 10px;
      }
    }
    .homeYingForm {
    .el-form-item__label {
        line-height: 20px !important
    }
    .el-button--primary {
      width: 100%;
    }
  }
  .zanweshu {
    padding: 100px;
    text-align: center;
    font-size: 24px;
    color: rgba(0, 0, 0, .5);
    width: 100%
  }
    .homeChanRigDetailsBtm-btm {
    img {
        // width: 100% !important;
        margin: 10px 0;
        max-width: 100% !important;
    }
    video {
        width: 100% !important;
        margin: 10px 0;
    }
  }
    .homeChanLft {
        .el-collapse-item__header {
            font-weight: 600 !important;
        }
    }
    .ceshiBaccolor {
        // background-color: #409EFF;
        color: #409EFF;
        font-weight: 600;
    }
    .newborr {
      background-color: #409EFF;
      font-size: 26px;
      color: #fff !important;
    }

    .newBoxChanChan {
      width: 100% !important;
    }
    .newhomeyingFuTop {
      padding-top: 0 !important;
    }
    .newhomeyingFu {
      img {
        // width: 100% !important;
          margin: 10px 0;
          max-width: 100% !important;
      }
      video {
          width: 100% !important;
          margin: 10px 0;
      }
    }
  </style>
  
  
  
  